import React, { useMemo } from 'react';
import * as Self from './module';
import { registerModule } from '../../app/modules/reactModules';
import ModuleStyle from './module.style';
import Heading from '../../components/global/Heading';
import RichText from '../../components/global/RichText';
import Score from '../../components/Score';
import getFeedbackSummary from '../../app/resources/feedbackSummary';
import getFeedbackPage from '../../app/resources/feedbackPage';
import ReviewsCarousel from '../../components/ReviewsCarousel';
import Button from '../../components/global/Button';
import isEmbedded from '../../app/helpers/isEmbedded';

export const getServerSideProps = async ({
  featured: _featured,
  random: _random,
}) => {
  const featured = _featured?.content === 'true';
  const random = _random?.content === 'true';

  const [feedback, page] = await Promise.all([
    getFeedbackSummary(),
    getFeedbackPage(0, undefined, featured, random),
  ]);
  const finalScore = feedback.statistics.find(
    (a) => a.question_type === 'final_score'
  );
  const score = Number(finalScore.value);
  const maxScore = Number(finalScore.maxscore);

  return {
    props: {
      feedback: {
        score: (score / maxScore) * 10,
        maxScore: 10,
        count: Number(finalScore.count),
      },
      loading: false,
      reviews: page.reviews,
    },
    maxage: 60,
    staleWhileRevalidate: 300,
  };
};

const ReviewsOverviewModule = ({
  title,
  feedbackText,
  feedback,
  loading,
  reviews,
  button,
  ...p
}) => {
  // when the module is on an embedded page (inside an iframe),
  // we want to force the button to open in a new tab
  const forceNewTab = isEmbedded();

  return (
    <ModuleStyle color="aliceBlue" xPaddingAsMargin {...p}>
      <div className="feedback-container">
        {!!title && <Heading title={title} />}
        {!!feedback && (
          <Score score={feedback.score} maxScore={feedback.maxScore} />
        )}
        {!!feedbackText && !!feedback && (
          <RichText
            className="subtext"
            content={feedbackText.content?.replace(
              '<!--COUNT-->',
              feedback.count
            )}
          />
        )}
      </div>
      <ReviewsCarousel
        maxContentHeight={100}
        loading={loading}
        reviews={reviews}
        nbOfSlidesPerView={2}
        button={() => {
          return (
            <Button
              large
              href={button.href}
              nofollow={button.nofollow}
              rel={button.rel}
              target={forceNewTab ? '_blank' : button.target}
            >
              {button.content}
            </Button>
          );
        }}
      ></ReviewsCarousel>
    </ModuleStyle>
  );
};

export default ReviewsOverviewModule;

registerModule({ ReviewsOverview: Self });
