import React from 'react';
import { useState } from 'react';
import StyledReviewsCarousel from './ReviewsCarousel.style';
import Carousel from './Carousel';
import Icon from './global/Icon';
import {
  ArrowLeft,
  ArrowRight,
} from '../app/helpers/icons';
import Button from './global/Button';
import { useBreakpoint } from '../app/providers/breakpointProvider';
import ReviewCard from './ReviewCard';

const ReviewsCarousel = ({
  loading = true,
  reviews,
  nbOfSlidesPerView = 3,
  button,
  maxContentHeight = null,
  ...p
}) => {
  const [prevEnabled, setPrevEnabled] = useState(false);
  const [nextEnabled, setNextEnabled] = useState(false);
  const swiperRef = React.useRef(null);
  const initialSlideRef = React.useRef(0);

  const layout = useBreakpoint().equalsOrUp('desktopSmall')
    ? 'desktop'
    : 'mobile';

  const carouselProps = {
    desktop: {
      key: 'desktop', // changing key forces remount
      slidesPerView: nbOfSlidesPerView,
      spaceBetween: 30,
      pagination: false,
      fullWidth: false,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
    },
    mobile: {
      key: 'mobile', // changing key forces remount
      itemWidth: 273,
      spaceBetween: 15,
      pagination: false,
    },
  }[layout];

  return (
    <StyledReviewsCarousel {...p}>
      {!loading && (
        <>
          <div className="carousel-container">
            <Button
              disabled={!prevEnabled}
              className="button-prev"
              variant="ghost"
              onClick={() => {
                swiperRef.current?.slidePrev();
              }}
            >
              <Icon {...ArrowLeft} title="Previous" />
            </Button>

            <Carousel
              {...carouselProps}
              initialSlide={initialSlideRef.current}
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
                setPrevEnabled(!swiper.isBeginning);
                setNextEnabled(!swiper.isEnd);
              }}
              onSlideChange={(swiper) => {
                initialSlideRef.current = swiper.activeIndex;
                if (prevEnabled !== !swiper.isBeginning) {
                  setPrevEnabled(!swiper.isBeginning);
                }
                if (nextEnabled !== !swiper.isEnd) {
                  setNextEnabled(!swiper.isEnd);
                }
              }}
            >
              {reviews?.map((review, ix) => (
                <ReviewCard
                  key={ix}
                  review={review}
                  maxContentHeight={maxContentHeight}
                />
              ))}
            </Carousel>
            <Button
              disabled={!nextEnabled}
              className="button-next"
              variant="ghost"
              onClick={() => {
                swiperRef.current?.slideNext();
              }}
            >
              <Icon {...ArrowRight} title="Next" />
            </Button>
          </div>
          {!!button &&
            <div className="button-container">
                {button()}
            </div>
          }
        </>
      )}
    </StyledReviewsCarousel>
  );
};
export default ReviewsCarousel;