import styled from '@emotion/styled';
import Container from '../../components/global/Container';
import ScoreStyle from '../../components/Score.style';

export default styled(Container)`
  --pt: 30px;
  --pl: 15px;
  --pb: 30px;
  --pr: 15px;
  padding: 30px;

  .feedback-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 30px;

    .subtext {
      color: ${({ theme }) => theme.colors.dimGray};
      a {
        font-weight: ${({ theme }) => theme.weights.semibold};
      }
    }

    ${ScoreStyle} {
      max-width: 255px;
    }

    ${({ theme }) => theme.media.desktopSmall} {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-left: 45px;
      padding-right: 45px;
      gap: 15px;
      --pt: 15px;
      --pb: 15px;
      ${ScoreStyle} {
        margin: 0 0 0 15px;
      }
    }
  }

  .button {
    text-align: center;
  }
`;
