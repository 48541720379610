import styled from '@emotion/styled';
import ButtonStyle from './global/Button.style';

export default styled.div`
  .carousel-container {
    position: relative;
    .button-prev,
    .button-next {
      position: absolute;
      width: 48px;
      height: 48px;
      padding: 3px;
      top: 50%;
      margin-top: -24px;
      pointer-events: none;
      display: none;

      ${({ theme }) => theme.media.desktopBig} {
        display: flex;
        pointer-events: initial;
      }
    }
    .button-prev {
      left: 50%;
      margin-left: -720px;
    }
    .button-next {
      right: 50%;
      margin-right: -720px;
    }
  }
  .button-container {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    ${({ theme }) => theme.media.tablet} {
      margin-top: 30px;
    }
    ${ButtonStyle} {
      min-width: 240px;
    }
  }
`;
